import {
  useTheme,
  Grid,
  Flex,
  Text,
  Link,
  Divider,
  Menu,
  MenuItem,
  IconAccountCircle,
  IconEmail,
  IconAdminPanelSettings,
  IconLogin,
  Image,
} from '@aws-amplify/ui-react';

import logo from '../ebase_logo_FullColor.png';

export function NavBar({ user, signOut, setPage }) {
  const { tokens } = useTheme();
  const userEmail = user.signInUserSession?.idToken?.payload.email;
  const userGroups = user.signInUserSession?.idToken?.payload['cognito:groups'];
  const isAdmin = userGroups && userGroups.includes('Admins');

  return (
    <Grid
      templateColumns="10fr 1fr"
      backgroundColor="var(--amplify-colors-blue-20)"
      height="80px">
      <Flex justifyContent="flex-start">
        <Link onClick={() => setPage('search')}>
          <Image
            alt="logo"
            src={logo}
            padding={tokens.space.medium}
            height="80px"
          />
        </Link>
      </Flex>
      <Flex justifyContent="center">
        <Menu
          alignItems="flex-start"
          trigger={
            <Text as="span" fontSize="50px">
              <IconAccountCircle />
            </Text>
          }>
          <MenuItem gap="10px">
            <IconEmail />
            {userEmail}
          </MenuItem>
          {isAdmin && (
            <MenuItem gap="10px" onClick={() => setPage('admin')}>
              <IconAdminPanelSettings />
              User Administration
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={signOut} gap="10px" width="100%">
            <IconLogin />
            Sign Out
          </MenuItem>
        </Menu>
      </Flex>
    </Grid>
  );
}
