import {
  Flex,
  Grid,
  View,
  SearchField,
  Placeholder,
  Divider,
} from '@aws-amplify/ui-react';
import { useState } from 'react';
import { addBackToTop } from 'vanilla-back-to-top';
import { getUserSessionToken } from '../getUserSessionToken';

export function SearchPage({ loading, setLoading }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const handleClick = () => {
    window.open("https://ebase.ai/demo.html");
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent:'center', alignItems:'center', padding: '25px', color: '#22336B'}}>
          <h3> Schedule a consultation at <a href="https://ebase.ai/demo.html" target="_blank">ebase.ai</a> </h3>
      </div>
      <Grid templateColumns="2fr 10fr 2fr">
        <View />
        {/* Search field */}
        <SearchField
          alignSelf="center"
          id="search"
          label="Search"
          placeholder="Ask here..."
          size="large"
          hasError={searchTerm === ''}
          onChange={(e) => setSearchTerm(e.target.value)}
          onSubmit={(searchTerm) => onSearch(searchTerm)}
          onClear={() => {
            console.log(`Clear results`);
            setSearchResults([]);
          }}
        />
        <View />
      </Grid>
      {/* Loading placeholders */}
      <Grid templateColumns="2fr 10fr 2fr" padding="50px">
        <View />

        {loading && (
          /*<>
            <Flex direction="column" padding="20px">
              {[...Array(15).keys()].map((n) => (
                <Flex direction="column" padding="10px" key={n}>
                  <Placeholder size="small" isLoaded={false} width="30%" />
                  <Placeholder size="large" isLoaded={false} height="50px" />
                  <Divider />
                </Flex>
              ))}
            </Flex>
          </>*/
          <div class='loader loader1'>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Use the Search results here to display */}
        <Flex class="search-results"
          as="div"
          dangerouslySetInnerHTML={{ __html: searchResults }}></Flex>
      </Grid>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '10vh', color: '#22336B'}}>
          <h2> Try searching one of the below...</h2>
      </div>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '22vh', color: '#22336B', fontSize: '17px', lineHeight: '2'}}>
      <ul>
        <li>What’s an onsite generation product developed to generate electricity on customer site?</li>
        <li>Do we sell CGAS?</li>
        <li>Who delivers parts to Minnesota and Michigan?</li>
        <li>What should be included in a bid?</li>
        <li>Where there any bids in April?</li>
      </ul>
      </div>
    </>
  );

  async function onSearch(searchQuery) {
    if (!searchQuery) return alert(`Please enter the search term!`);

    const { accessToken, idToken } = await getUserSessionToken();
    sessionStorage.setItem("globalToken", idToken); 

    setLoading(true);
    setSearchResults([]);
    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
      const response = await fetch(
        `https://1x4oov6n4k.execute-api.us-east-1.amazonaws.com/Demo/ebaseapp`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: idToken,
          },
          body: JSON.stringify(searchQuery),
        }
      );

      if (!response.ok) {
        return handleError({ response });
      }
      const searchResult = await response.text();
      console.log('onSearch ->  searchResult', searchResult);
      setSearchResults(searchResult);
      // Back to top button
      addBackToTop({
        diameter: 60,
        backgroundColor: '#bbb',
        textColor: 'blue',
      });
    } catch (ex) {
      console.error(ex);
      return handleError({ exception: ex });
    } finally {
      setLoading(false);
    }

    function handleError({ response, exception }) {
      return alert(
        `We're sorry your search request for term: ${searchQuery} could not be completed. \n\n Server Response:\n ${
          response?.status ||
          '' + response?.statusText ||
          '' + exception?.message ||
          ''
        }`
      );
    }
  }
}
