import {
  Grid,
  Flex,
  Loader,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import { useEffect, useState, useRef } from 'react';
import React from "react";
import { Hub } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { AdminPage } from '../Admin/AdminPage';
import { NavBar } from './NavBar';
import { SearchPage } from './SearchPage';

export function Home() {
  const sessionKey = 'CURRENT_PAGE';
  const initialPage = sessionStorage.getItem(sessionKey) || 'search';
  const [page, setPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const { signOut, user } = useAuthenticator();

  useEffect(() => {
    // console.log('useEffect ->  page', page);
    sessionStorage.setItem(sessionKey, page);
    return () => {
      sessionStorage.removeItem(sessionKey);
    };
  }, [page]);

  return (
    <Grid
      maxWidth="100vw"
      justifyContent="center"
      templateColumns="98vw"
      templateRows="150px 10fr">
      <View columnSpan="2">
        <NavBar signOut={signOut} user={user} setPage={setPage} />
        <Flex height="30px">
          <Loader
            variation="linear"
            size="small"
            display={loading ? 'inline-block' : 'none'}
          />
        </Flex>
      </View>
      <View columnSpan="2">
        {page === 'search' && (
          <SearchPage loading={loading} setLoading={setLoading} />
        )}
        {page === 'admin' && (
          <AdminPage loading={loading} setLoading={setLoading} />
        )}
      </View>
    </Grid>
  );
};
function AutosignIn() {
  const username = "anthonyloss24@aol.com"
  const password = "Foghornleghorn1!"

  return (
    Auth.signIn(username, password)
  );
}

window.onload = function() {
  if(!window.location.hash) {
      window.location = window.location + '#loaded';
      setTimeout(function(){
      window.location.reload();
      AutosignIn()
    }, 5000);
  }
}

AutosignIn()

//window.onload = function(){
  //document.getElementById("radix-id-0-8").value = "aloss@functionfactories.com";
  //document.getElementById("radix-id-0-10").value = "Cypag1234!";
  //document.forms[0].submit();
//}