/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7482c60e-69e5-4eb9-ad17-204bc78e1721",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Z4VBjsWRH",
    "aws_user_pools_web_client_id": "6fl7ko75q38qfgdpv96ftn22u0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://8csjx022d1.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
